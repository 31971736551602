// Code generated by codegen. DO NOT EDIT!
//
// Use 'yarn codegen' to regenerate this file based on schema and
// file defined operations.

import { useMutation, UseMutationOptions } from "@tanstack/react-query";

import * as Types from "../../../gql/types";

import { fetcher } from "lib/client/graphql";
export type LayoutOnlineReceptionFormSendMessageMutationVariables =
  Types.Exact<{
    input: Types.OmnichannelSendMessageInput;
  }>;

export type LayoutOnlineReceptionFormSendMessageMutation = {
  __typename?: "Mutation";
  sendMessage: { __typename: "OmnichannelSendMessagePayload" };
};

export const LayoutOnlineReceptionFormSendMessageDocument = `
    mutation LayoutOnlineReceptionFormSendMessage($input: OmnichannelSendMessageInput!) {
  sendMessage(input: $input) {
    __typename
  }
}
    `;
export const useLayoutOnlineReceptionFormSendMessageMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    LayoutOnlineReceptionFormSendMessageMutation,
    TError,
    LayoutOnlineReceptionFormSendMessageMutationVariables,
    TContext
  >,
) =>
  useMutation<
    LayoutOnlineReceptionFormSendMessageMutation,
    TError,
    LayoutOnlineReceptionFormSendMessageMutationVariables,
    TContext
  >(
    ["LayoutOnlineReceptionFormSendMessage"],
    (variables?: LayoutOnlineReceptionFormSendMessageMutationVariables) =>
      fetcher<
        LayoutOnlineReceptionFormSendMessageMutation,
        LayoutOnlineReceptionFormSendMessageMutationVariables
      >(LayoutOnlineReceptionFormSendMessageDocument, variables)(),
    options,
  );
