import { isAfter, isBefore } from "date-fns";

import { Brand } from "types";

const promotionStartDate = new Date("2024-12-17T11:00:00+01:00");
const promotionEndDate = new Date("2025-02-21T00:00:00+01:00");

export const isAssetPromotionEnabled = (
  assetId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  brand: Brand,
): boolean => {
  const isAfterPromotionStart = isAfter(new Date(), promotionStartDate);
  const isBeforePromotionEnd = isBefore(new Date(), promotionEndDate);
  if (
    isAfterPromotionStart &&
    isBefore(new Date(), new Date("2025-01-18T00:00:00+01:00")) &&
    assetId == "545e9294-c4d2-4c12-9f1d-99ed322a6adf" //LMTN
  ) {
    return true;
  }

  return (
    isAfterPromotionStart &&
    isBeforePromotionEnd &&
    [
      "c1e4c1d6-14f5-402b-bb0c-4f0aa5b821d5", //LSAN
      "d1e4c1d6-14f5-402b-bb0c-4f0aa5b821d6", //LVGA
      "167f2b95-95de-4b29-bbab-5a2ab5a6db39", //LBLD
      "58b8481b-c493-4024-a0dc-a80430c90ce0", //LGCT
      "242b3676-bb54-47e1-8c1d-73307ec71f75", //LCDR
      "2045c99b-7c4c-4be4-bd3d-b71de803b065", //LCTN
      "2b2c6df4-819d-4ca0-a331-52fe59050a43", //LCSM
      "41753207-ae70-459b-8ffc-c7130e04d25d", //LVJB
      "a29d75a0-9c88-41da-8ece-62d53362e77b", //LVAB
    ].includes(assetId)
  );
};

export const promotionForAsset = (
  assetId: string,
  brand: Brand,
): Promotion | null =>
  isAssetPromotionEnabled(assetId, brand) ? promotionForBrand(brand) : null;

export const promotionForBrand = (brand: Brand): Promotion | null => {
  const isAfterPromotionStart = isAfter(new Date(), promotionStartDate);
  const isBeforePromotionEnd = isBefore(new Date(), promotionEndDate);
  if (
    ["libere", "koisi"].includes(brand.id) &&
    isAfterPromotionStart &&
    isBeforePromotionEnd
  ) {
    return {
      discount:
        brand.id === "koisi"
          ? { type: "fixed", amount: 10 }
          : { type: "upTo", amount: 15 },
      promoCode: brand.id === "koisi" ? "KOISI2025" : "LIB2025",
      startDate: "01/01/2025",
      endDate: "28/02/2025",
      maxNights: 3,
      loyaltyApplies: brand.id === "libere",
    };
  }
  return null;
};

export type Promotion = {
  discount: { type: "fixed" | "upTo"; amount: number };
  promoCode: string;
  startDate: string;
  endDate: string;
  maxNights: number;
  translateKey?: string;
  loyaltyApplies?: boolean;
};
