import { Translate } from "lib/i18n/client";

export type Reservation = {
  id: string;
  purchase: ReservationPurchase;
  assetId: string;
  businessSegment: {
    segment: string;
  };
  status: "PENDING" | "CONFIRMED" | "CHECKED_IN" | "CHECKED_OUT" | "CANCELLED";
  confirmationNumber: string;
  requestedCategory: {
    id: string;
    name: string;
    type: "DORM" | "BED" | "ROOM" | "APARTMENT";
  };
  assignedSpaceConfig?: {
    categoryId: string;
    space: {
      id: string;
      name: string;
      floor: string;
      status: string;
    };
  };
  createdDate: string;
  cancellationDate?: string | null;
  checkinDate: string;
  checkoutDate: string;
  mainInhabitant: Inhabitant;
  booker?: Inhabitant;
  group: ReservationGroup;
  checkinContext: ReservationCheckinContext;
};

export type ReservationCheckinContext = {
  entryFormsPending: number;
  groupIsReady: boolean;
};

export type ReservationPurchase = {
  id: string;
  assetId: string;
  businessSegment: {
    segment: string;
  };
  checkinDate: string;
  checkoutDate: string;
  mainInhabitant: Inhabitant;
  booker?: Inhabitant;
};

export type ReservationGroup = {
  adultCount: number;
  childCount: number;
  status: "COMPLETE" | "INCOMPLETE";
  inhabitants: Array<Inhabitant>;
  invites: Array<Invite>;
};

export type Inhabitant = {
  id: string;
  verified: boolean;
  nationalityCode: string;
  nationalityProvince: string;
  email: string;
  phone?: string;
  firstName?: string;
  lastName: string;
  secondLastName: string;
  gender: Gender;
  birthDate: string;
  identityDocumentType: string;
  identityDocumentNumber: string;
  identityDocumentIssueDate: string;
  identityDocumentExpirationDate?: string;
  identityVerifications: Array<{ id: string; verifiedAt: string }>;
  status: string;
  marketingCommunicationsAccepted: boolean;
  conditionsAccepted: boolean;
  dataCommitment: boolean;
  complete: boolean;
};

type Invite = {
  id: string;
  first_name: string;
  last_name: string;
};

export type Money = {
  amount: number;
  currency: string;
};

export type MoneyV2 = {
  amount: string;
  currency: string;
};

type Gender = "MALE" | "FEMALE";

export type StoreProduct = {
  id: string;
  name: string;
  description: string;
  image?: {
    uri: string;
    srcSet: string;
    altText: string;
  };
  chargingType: "once" | "per_night" | "per_person" | "per_person_per_night";
  price: {
    gross: Money;
    old?: Money;
  };
  preferenceFields: Array<{
    key: string;
    type: "text" | "textarea";
    label: string;
    description: string;
    required: boolean;
  }>;
  availability?: {
    unitsCount: number;
    availableForSale: boolean;
  };
};

export type ItemsResponse = {
  items: Array<ReservationItem>;
  products: Array<StoreProduct>;
};

export type ReservationItem = {
  id: string;
  type: string;
  productId: string;
  value: Money;
  consumptionTime: string;
  fulfilmentStatus: "pending" | "confirmed" | "cancelled";
};

export type StoreCategory = {
  id: string;
  name: string;
  categories: StoreCategory[];
  icon: {
    data: string;
  };
  products: StoreProduct[];
};

export class HttpError extends Error {
  public readonly statusCode: number;
  public readonly errorCode?: string;
  public readonly errorMessage?: string;

  constructor(
    message: string,
    statusCode: number,
    errorCode?: string,
    errorMessage?: string,
  ) {
    super(`${message}. Status code: ${statusCode}; Error code: ${errorCode}`);
    this.statusCode = statusCode;
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
  }
}

export const ErrorMap = {
  200: "errors:defaultError.ok",
  400: "errors:defaultError.bad_request",
  401: "errors:defaultError.unauthorized",
  403: "errors:defaultError.forbidden",
  404: "errors:defaultError.not_found",
  409: "errors:defaultError.conflict",
  429: "errors:defaultError.too_many_requests",
  499: "errors:defaultError.canceled",
  500: "errors:defaultError.server_error",
  501: "errors:defaultError.not_implemented",
  503: "errors:defaultError.service_unavailable",
  504: "errors:defaultError.gateway_timeout",
} as { [key: number]: string };

export interface ErrorDetail {
  "@type": string;
}

export const ErrorInfoType = "type.googleapis.com/google.rpc.ErrorInfo";

export interface ErrorInfo extends ErrorDetail {
  reason: string;
  metadata?: { [key: string]: string };
}

export class StatusHttpError extends Error {
  public readonly statusCode: number;
  public readonly errorCode: number;
  public readonly errorMessage: string;
  public readonly errorDetails: Array<ErrorDetail>;

  constructor(
    statusCode: number,
    errorCode: number,
    errorMessage: string,
    errorDetails?: Array<ErrorDetail>,
  ) {
    super(`HTTP Status code: ${statusCode}; Error code: ${errorCode}`);
    this.statusCode = statusCode;
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
    this.errorDetails = errorDetails || [];
  }
}

export const errorTranslation = (
  t: Translate,
  statusErr: unknown,
  customErrorPrefix = "error.",
): string => {
  if (!(statusErr instanceof StatusHttpError)) {
    return t("errors:defaultError.unknown_error");
  }
  if (statusErr.errorDetails && statusErr.errorDetails.length > 0) {
    const details = statusErr.errorDetails[0];
    if (details["@type"] === ErrorInfoType) {
      // We could add more types here
      return t(
        `${customErrorPrefix}${(details as ErrorInfo).reason.toLowerCase()}`,
        {
          ...(details as ErrorInfo).metadata,
          defaultValue:
            ErrorMap[statusErr.errorCode] ||
            "errors:defaultError.unknown_error",
        },
      );
    }
  }
  return t(
    ErrorMap[statusErr.errorCode] || "errors:defaultError.unknown_error",
  );
};
